.headerText {
  font-size: 30px;
  color: #1eb789;
}

.UsersContainer {
  max-width: 792px;
}
.addUserBtn {
  border: 1px solid #1eb789;
  width: 189px;
  height: 58px;
  border-radius: 50px;
  cursor: pointer;
}
.addUserText {
  font-size: 14px;
  font-family: "avenir" !important;
  font-weight: 600;
  color: #1eb789;
}
td {
  padding-left: 0px !important;
}
.tableStyles {
  min-width: 600px;
}
.tableStylesContainer {
  /* min-width: 600px!important; */
  overflow-x: scroll !important ;
}
