.Buttonoutline {
  border-width: 2px !important;
  border-radius: 8px !important;
  border-color: rgba(114, 165, 150, 1) !important;
  width: 100% !important;
  height: 65px !important;
  font-family: "avenir" !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  color: rgba(114, 165, 150, 1) !important;
  font-size: 16px !important;
}
.Buttonoutline:hover {
  border-width: 2px !important;

  background-color: rgba(114, 165, 150, 1) !important;
  border-radius: 8px !important;
  border-color: #ffffff !important;
  width: 100% !important;
  height: 65px !important;
  font-family: "avenir" !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  color: #ffffff !important;
  font-size: 16px !important;
}
.ButtonoutlineWithNohoverEffect {
  border-width: 2px !important;

  border-radius: 8px !important;
  border-color: rgba(114, 165, 150, 1) !important;
  width: 100% !important;
  height: 65px !important;
  font-family: "avenir" !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  color: rgba(114, 165, 150, 1) !important;
  font-size: 16px !important;
}

.ButtonoutlineWithNohoverEffect:hover {
  border-width: 2px !important;

  background-color: #ffffff !important;
  border-radius: 8px !important;
  border-color: rgba(114, 165, 150, 1) !important;
  width: 100% !important;
  height: 65px !important;
  font-family: "avenir" !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: rgba(114, 165, 150, 1) !important;
}
.ButtonoutlineBlack {
  border-width: 2px !important;

  border-radius: 8px !important;
  border-color: #000 !important;
  width: 100% !important;
  height: 65px !important;
  font-family: "avenir" !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  color: #000 !important;
  font-size: 16px !important;
}
.ButtonoutlineBlack:hover {
  border-width: 2px !important;

  background-color: #000 !important;
  border-radius: 8px !important;
  border-color: white !important;
  width: 100% !important;
  height: 65px !important;
  font-family: "avenir" !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  color: white !important;
  font-size: 16px !important;
}
