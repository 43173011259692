.headerText {
  font-size: 30px;
  color: #1eb789;
}

.UsersContainer {
  max-width: 792px;
}
.addUserBtn {
  border: 1px solid #1eb789;
  width: 189px;
  height: 58px;
  border-radius: 50px;
  cursor: pointer;
}
.addUserText {
  font-size: 14px;
  font-family: "avenir" !important;
  font-weight: 600;
  color: #1eb789;
}
.FormTitle {
  font-size: 18px;
  color: #373737;
  font-family: "avenir" !important;
  font-weight: 500;
}
.textFormContainer {
  width: 100% !important;
  position: relative;
}
.TabFormInput {
  background-color: #fafafa !important;
  border-radius: 8px !important;
  width: 100% !important;
  height: 65px !important;
  text-align: left !important;
  padding-left: 10px;
  color: #373737 !important;
  font-size: 16px !important;
  font-family: "avenir" !important;
  font-weight: 900 !important;
  border: 1px solid #fafafa !important;
}
.btnSave {
  background-color: #1eb789 !important;
  color: #fff !important;
  font-size: 16px !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  border-radius: 50px;
  width: 239px;
  height: 64px;
  cursor: pointer;
}
.btnCancel {
  background-color: #fff !important;
  color: #373737 !important;
  font-size: 16px !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  border-radius: 50px;
  width: 239px;
  height: 64px;
  cursor: pointer;
}
.btnCancel:hover {
  border: 1px solid #373737;
}
.TabFormInput {
  background-color: #fafafa !important;
  border-radius: 8px !important;
  width: 100% !important;
  height: 65px !important;
  text-align: left !important;
  padding-left: 10px;
  color: #373737 !important;
  font-size: 16px !important;
  font-family: "avenir" !important;
  font-weight: 900 !important;
  border: 1px solid #fafafa !important;
}
.textFormContainer {
  width: 100% !important;
}
.textFormContainer input[type="text"]:focus,
.textFormContainer input[type="email"]:focus,
.textFormContainer input[type="password"]:focus {
  background-color: #fafafa !important;
  border-radius: 8px !important;
  width: 100% !important;
  height: 65px !important;
  text-align: left !important;
  padding-left: 10px;
  color: #373737 !important;
  font-size: 16px !important;
  font-family: "avenir" !important;
  font-weight: 900 !important;
  border: 1px solid #fafafa !important;
  box-shadow: 0 0px 1px #eff8f5 inset, 0 0 8px #fff !important;
  outline: 0 none !important;
}
.input-addon {
  position: absolute;
  right: 15px;
  top: 18px;
}
.input-addon-img {
  width: 30px;
  height: 30px;
}
