.Button2 {
  background-color: #0f0145 !important;
  border-radius: 8px !important;
  border-color: #0f0145 !important;
  width: 100% !important;
  height: 65px !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  font-size: 20px !important;

  position: relative;
}
.RightAiIcon {
  /* position: absolute; */
  width: 37px;
  height: 37px;
  margin-left: 20px;
  /* top: 13px;
  right: 18px; */
}
