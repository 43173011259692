.headerText {
  font-size: 30px;
  color: #1eb789;
}
td {
  padding-left: 0px !important;
}
.tabText {
  font-size: 20px;
  color: #373737;
}
.tabContainer {
  border-bottom-width: 1px;
  border-bottom-color: #373737;
  border-bottom-style: solid;
  cursor: pointer;
  height: 50px;
}
.tabContainerActive {
  border-bottom-width: 3px;
  border-bottom-color: #1eb789;
  border-bottom-style: solid;
  cursor: pointer;
}
.abonnmentContainer {
  min-height: 77px;
  max-width: 792px;
  border: 1px solid #1eb789;
  border-radius: 8px;
}
.abonnmentContainerempty {
  min-height: 77px;
  max-width: 396px;
  border: 1px solid #1eb789;
  border-radius: 8px;
}
.abonnmentContainer0 {
  max-width: 792px;
}
.abonnmentContainer0empty {
  max-width: 396px;
}
.text-stop-sub {
  cursor: pointer;
  font-size: 12px;
  font-family: "avenir" !important;
  font-weight: 500;
  color: #373737;
}
.text-curent-sub-Header {
  font-size: 14px;
  font-family: "avenir" !important;
  font-weight: 500;
  color: #373737;
  text-align: left;
}
.text-curent-sub {
  font-size: 18px;
  font-family: "avenir" !important;
  font-weight: 600;
  color: #1eb789;
  text-align: left;
}
.text-curent-sub-date {
  font-size: 14px;
  font-family: "avenir" !important;
  font-weight: 600;
  color: #6f6f6f;
  text-align: left;
}
.text-curent-sub-price {
  font-size: 18px;
  font-family: "avenir" !important;
  font-weight: 900;
  color: #1eb789;
  text-align: left;
}
.text-curent-sub-count {
  font-style: italic;
  color: #0f0145;
  font-size: 12px;
  font-family: "avenir" !important;
  font-weight: 500;

  text-align: left;
}
.abonnmentCardsContainer {
  max-width: 821px !important;
}
.cardHeader {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  height: 79px;
  background-color: #f1f1f1;
}
.cardBody {
  height: 179px;
  background-color: #fafafa;
}
.cardFooter {
  height: 161px;
  background-color: #fafafa;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: grid;
}
.card-Header-text {
  font-size: 18px;
  font-family: "avenir" !important;
  font-weight: 600;
  color: #373737;
}
.card-sub-Header-text {
  font-size: 20px;
  font-family: "avenir" !important;
  font-weight: 900;
  color: #373737;
}
.card-body-text {
  font-size: 14px;
  font-family: "avenir" !important;
  font-weight: 500;
  color: #373737;
}
.cardFooterBtn {
  border: 1px solid #373737;
  border-radius: 8px;
  height: 47px;
  cursor: pointer;
  background-color: #fff;
  align-self: flex-end;
}
.btnText {
  font-size: 14px;
  font-family: "avenir" !important;
  font-weight: 600;
  color: #252526;
}
.ForceGreen {
  background-color: #1eb789 !important;
}
.ForceWhite {
  color: #fff !important;
}
.ForceGreenText {
  color: #1eb789 !important;
}
.whiteLigne {
  height: 1px;
  background-color: #fff;
  width: 100%;
}
.ForceWhiteBorder {
  border-color: #fff !important;
}
.codesInput {
  background-color: #1eb789 !important;
  border-radius: 8px !important;
  width: 84px !important;
  height: 39px !important;
  border: 1px solid #fff !important;
  text-align: center !important;
  color: #fff !important;
  font-size: 16px !important;
  font-family: "avenir" !important;
  font-weight: 900 !important;
}
.textContainer input[type="text"]:focus {
  background-color: #1eb789 !important;
  border-radius: 8px !important;
  width: 84px !important;
  height: 39px !important;
  border: 1px solid #fff !important;
  text-align: center !important;
  color: #fff !important;
  font-size: 16px !important;
  font-family: "avenir" !important;
  font-weight: 900 !important;
  box-shadow: 0 0px 1px #eff8f5 inset, 0 0 8px #1eb789 !important;
  outline: 0 none !important;
}
.FormTitle {
  font-size: 18px;
  color: #373737;
  font-family: "avenir" !important;
  font-weight: 500;
}
.TabFormInput {
  background-color: #fafafa !important;
  border-radius: 8px !important;
  width: 100% !important;
  height: 65px !important;
  text-align: left !important;
  padding-left: 10px;
  color: #373737 !important;
  font-size: 16px !important;
  font-family: "avenir" !important;
  font-weight: 900 !important;
  border: 1px solid #fafafa !important;
}
.textFormContainer {
  width: 100% !important;
}
.textFormContainer input[type="text"]:focus {
  background-color: #fafafa !important;
  border-radius: 8px !important;
  width: 100% !important;
  height: 65px !important;
  text-align: left !important;
  padding-left: 10px;
  color: #373737 !important;
  font-size: 16px !important;
  font-family: "avenir" !important;
  font-weight: 900 !important;
  border: 1px solid #fafafa !important;
  box-shadow: 0 0px 1px #eff8f5 inset, 0 0 8px #fff !important;
  outline: 0 none !important;
}
.btnSave {
  background-color: #1eb789 !important;
  color: #fff !important;
  font-size: 20px !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  border-radius: 50px;
  width: 239px;
  height: 64px;
  cursor: pointer;
}
.btnSaveTable {
  background-color: #1eb789 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  border-radius: 50px;
  width: 135px;
  height: 37px;
  cursor: pointer;
}
.tableStyles {
  min-width: 600px;
}
.tableStylesContainer {
  /* min-width: 600px!important; */
  overflow-x: scroll !important ;
}
