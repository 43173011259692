.btnLeft {
  background-color: white;
  color: black;
  padding: 5px 0;
  border-radius: 20px;
  width: 120px;
  min-width: 60px;
  border-style: solid;
  border-width: 1px;
  margin-left: 40px !important;
}
.btnRight {
  background-color: #1FB789;
  color: white;
  padding: 5px 0;
  border-radius: 20px;
  width: 120px;
  min-width: 60px;
  border-style: none;
  margin-right: 40px !important;

}
@media screen and (max-width: 600px) {
    .btnLeft {
  background-color: white;
  color: black;
  padding: 5px 0;
  border-radius: 20px;
  width: 85px;
  min-width: 60px;
  border-style: solid;
  border-width: 1px;
  margin-left: 25px !important;
}
.btnRight {
  background-color: #1FB789;
  color: white;
  padding: 5px 0;
  border-radius: 20px;
  width: 85px;
  min-width: 60px;
  border-style: none;
  margin-right: 25px !important;

}
    
}
