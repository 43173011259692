.AppBackground {
  background-color: #f3f8fa;
  height: 100% !important;
  width: 100% !important;
}
.AppFrame1 {
  height: 78vh;
  width: 92vw !important;
  border-radius: 8px;
  margin-top: 4vh;
  padding: 0 !important;
  overflow: scroll;
  /* background: red; */
}
