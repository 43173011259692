.newCodeText {
  opacity: 1;
  color: rgba(30, 183, 137, 1);
  font-size: 32px;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  /* background-color: red ;
  width: 400px; */
}
.newCodeTextMobile {
  opacity: 1;
  color: rgba(30, 183, 137, 1);
  font-size: 32px;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  min-width: 300px;
}
.savebtns {
  height: 54px !important;
  border-width: 1px !important;
}
.dotsIcon {
  width: 39px;
}
.AppInputNewMessage {
  height: 88px !important;
}
.labelStyle {
  font-family: "avenir" !important;
  font-size: 20px !important;
  font-family: "avenir" !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 1) !important;
}
.text-piece {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  font-family: "avenir" !important;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
}
@media screen and (max-width: 600px) {
  .text-piece {
    opacity: 1;
    color: rgba(0, 0, 0, 1);
    font-size: 18px;
    font-family: "avenir" !important;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
  }
  .labelStyle {
    font-family: "avenir" !important;
    font-size: 18px !important;
    font-family: "avenir" !important;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 1) !important;
  }
}

.MessageTypeIcon {
  width: 20px;
  height: 20px;
}
.MessageTypeIcon {
  width: 20px;
  height: 20px;
}
.plusIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.plusCodetext {
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: "avenir" !important;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
}
.EnvoyerIcon {
  width: 20px;
  height: 20px;
}
.ApercuCodeIcon {
  max-width: 150px !important;
  max-height: 150px !important;
  padding: 0 !important;
}
.dropdownItem {
  height: 57px !important;
}
.dropdownItem:active {
  background-color: rgba(114, 165, 150, 1) !important;
}
.messageItem,
.imageContainer,
.react-pdf__Page__canvas,
.react-pdf__Page__textContent {
  width: 200px !important;
  height: 200px !important;
}
.image-selector-container:has(audio) {
  min-width: 350px !important;
  width: 350px !important;
}
.image-selector-container:has(.imageContainer) {
  min-width: 230px !important;
  width: 230px !important;
}
.image-selector-container .btnRmv {
  top: -8px;
  right: -15px;
}
.image-selector-container:has(.react-pdf__Page) {
  min-width: 250px !important;
  width: 250px !important;
}
.btnRmv {
  position: absolute;
  top: 8px;
  right: 8px;
  border: none;
  background-color: white;
  /* border-radius: 50%; */
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.messageItem {
  position: relative;
}
.audioSelectDrop {
  max-width: 100%;
  border-color: #72a596 !important;
  background-color: #eff8f5 !important;
  height: 65px;
  border-width: 1px !important;
  cursor: pointer;
  border-radius: 8px;
  border-style: solid;
}
.drop-tex {
  opacity: 1;
  color: #000;
  font-size: 32px;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
}
.plusCodetext .mb-3 {
  width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.historyIcon {
  width: 23px;
  height: 13px;
  margin-top: 7px;
}

.CodeTypeIconUrl {
  width: 32px;
  /* height: 32px; */
}
.CodeTypeIconFacebook {
  width: 18px;
  /* height: 32px; */
}
.CodeTypeIconinstagram {
  width: 25px;
  /* height: 32px; */
}
.CodeTypeIconlikdin {
  width: 28px;
  /* height: 32px; */
}
.CodeTypeIconWhatsapp {
  width: 32px;
  /* height: 32px; */
}
.CodeTypeIconpay {
  width: 32px;
  /* height: 32px; */
}
.CodeTypeIconstores {
  width: 40px;
  /* height: 32px; */
}
.CodeTypeIconyoutube {
  width: 32px;
  /* height: 32px; */
}
.CodeTypeIconImage {
  width: 28px;
  /* height: 32px; */
}
.CodeTypeIconVideo {
  width: 36px;
  /* height: 32px; */
}
.CodeTypeIconAudio {
  width: 22px;
  /* height: 32px; */
}
.CodeTypeIconpdf {
  width: 28px;
  /* height: 32px; */
}
