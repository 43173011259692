.AuthContainerLogin {
  width: 100%;
  height: 100% !important;
  /* background-color: red; */
  padding-left: 3vw;
  padding-right: 20vw;
  padding-top: 5vh;
  position: relative;
}
@media screen and (max-width: 600px) {
  .AuthContainerLogin {
    padding-left: 0vw;
    padding-right: 0vw;
  }
}


.iconTopContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.iconTop {
  width: 25px;
  height: 30px;
  margin-left: 14px;
  margin-bottom: 7px;
}
.headerTextAuth {
  opacity: 1;
  color: #373737;
  font-size: 40px;
  font-family: "avenir" !important;
  font-weight: 900;
  font-family: "avenir";
  letter-spacing: 0px;
  text-align: center;
}
.forgotPasswordContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  text-align: end;
  opacity: 1;
  color: rgba(29, 158, 220, 1);
  font-size: 18px;
  font-family: "avenir" !important;
  font-weight: 500;
  font-style: Medium;
  letter-spacing: 0px;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}
.forgotPasswordText {
  background-color: red;
}
.between {
  height: 1vh;
}
.signInText {
  display: flex;
  font-family: "avenir" !important;
  font-weight: 600;
  opacity: 16;
  color: rgba(55, 55, 55, 1);
  font-size: 10px;
  text-align: left;
  cursor: pointer;
}
.green-text {
  color: #1eb789;
  cursor: pointer;
  text-decoration: none;
}
.RightIcon {
  width: 22px;
  height: 22px;
  cursor: pointer;
}
