.newCodeTextmessageMobile {
  opacity: 1;
  color: rgba(30, 183, 137, 1);
  font-size: 25px;
  font-family: "avenir" !important;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  min-width: 300px;
}
.newCodeTextmessage {
  opacity: 1;
  color: rgba(30, 183, 137, 1);
  font-size: 32px;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  min-width: 300px;
}
.dotsIcon {
  width: 39px;
}
textarea {
  resize: none !important;
}
.AppInputNewMessage {
  max-height: 80px !important;
}
.btnEnvoyer {
  min-width: 150px !important;
  height: 54px !important;
}
.AppInputNewMessage::placeholder {
  color: #7cac9e !important;
  font-size: 16px;
  font-family: "avenir" !important;
  font-weight: 400;
}
.mt0 {
  margin-top: 0px !important;
}
.text-piece1 {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: "avenir" !important;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
}
.MessageTypeIconYoutube {
  width: 20px;
}
.MessageTypeIconvideo {
  width: 25px;
}
.MessageTypeIconAudio {
  width: 17px;
}
.MessageTypeIconImage {
  width: 23px;
}
.MessageTypeIconpdf {
  width: 20px;
  height: 20px;
}

.plusIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.plusCodetext {
  color: rgba(0, 0, 0, 1);
  /* color: red !important; */
  font-size: 14px !important;
  font-family: "avenir" !important;
  font-weight: 500 !important;
  letter-spacing: 0px !important;
  text-align: left !important;
}
.relativeBtn {
  position: relative;
}
.EnvoyerIcon {
  width: 20px;
  height: 20px;
}
.EnvoyerIconSend {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 18px;
  right: 20px;
}
.dropdownItem {
  height: 57px !important;
}
.dropdownItem:active {
  background-color: rgba(114, 165, 150, 1) !important;
}
.messageItem,
.imageContainer,
.react-pdf__Page__canvas,
.react-pdf__Page__textContent {
  width: 200px !important;
  height: 200px !important;
}
.messageItem:has(audio) {
  min-width: 350px !important;
  width: 350px !important;
}
.messageItem:has(.imageContainer) {
  min-width: 250px !important;
  width: 250px !important;
}
.messageItem:has(img):not(:has(audio)) {
  min-width: 250px !important;
  width: 250px !important;
}
.btnRmv {
  position: absolute;
  top: -10px;
  right: -10px;
  border: none;
  background-color: white;
  /* border-radius: 50%; */
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.messageItem {
  position: relative;
}
.audioSelectDrop {
  max-width: 100%;
  border-color: #72a596 !important;
  background-color: #eff8f5 !important;
  height: 65px;
  border-width: 1px !important;
  cursor: pointer;
  border-radius: 8px;
  border-style: solid;
}
.drop-tex {
  opacity: 1;
  color: #000;
  font-size: 32px;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
}
.plusCodetext .mb-3 {
  width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.historyIcon {
  width: 23px;
  height: 13px;
  margin-top: 7px;
}
.buttonResend {
  border-radius: 8px;
  border: 1px solid #1eb789;
  background-color: #fff;
  color: #1eb789;
  padding: 10px 20px;
  font-size: 16px;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  min-width: 206px !important;
}
.buttonResend:hover {
  border-radius: 8px;
  border: 1px solid #1eb789;
  background-color: #1eb789;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
}
