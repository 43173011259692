.iconTopContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 160%;
}

.iconTop {
  width: 25px;
  height: 30px;
  margin-left: 14px;
  margin-bottom: 7px;
}

.headerTextSignUp {
  opacity: 1;
  color: #373737;
  font-size: 30px;
  font-family: "avenir" !important;
  font-weight: 900;
  font-family: "avenir";
  letter-spacing: 0px;
  text-align: center;
}
@media screen and (max-width: 550px) {
  .headerTextSignUp {
    font-size: 20px;
  }
  
}

.forgotPasswordContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  text-align: end;
  opacity: 1;
  color: rgba(29, 158, 220, 1);
  font-size: 18px;
  font-family: "avenir" !important;
  font-weight: 500;
  font-style: Medium;
  letter-spacing: 0px;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}
.forgotPasswordText {
  background-color: red;
}
.between {
  height: 2vh;
}
.checkboxContainer {
  width: 28px;
}
.signUpText {
  display: flex;
  font-family: "avenir" !important;
  font-weight: 600;
  opacity: 1;
  color: rgba(55, 55, 55, 1);
  font-size: 18px;
  text-align: left;
  /* background-color: red; */
  width: 160%;
}

.checkboxContainer {
  margin-right: 10px; /* Adjust spacing between checkbox and text */
}

.green-text {
  color: #1eb789;
  cursor: pointer;
}
.cursorpointertext {
  color: #1eb789;
  cursor: pointer;
}

.cropSection {
  /* display: flex; TODO66 */
  align-items: center;
  width: 100%;
}
.cropSectionBtn {
  margin-right: 20px;
  margin-left: 20px;
  cursor: pointer;
}
.LeftIcon {
  width: 22px;
  height: 22px;
}
.RightIcon {
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.MargingButtomMobile {
  margin-bottom: 110px;
}
.MargingTopMobile {
  /* margin-top: 10px; */
}
