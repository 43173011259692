.PhoneInput {
  background-color: #eff8f5 !important;
  height: 65px !important;
  border-radius: 9px !important;
  border: 1px solid #72a596 !important;
}
.PhoneInputInput {
  border-width: 0px;
  background-color: #eff8f5;
  font-family: "avenir" !important;
}
.PhoneInputCountry {
  margin-left: 15px;
}
.PhoneInputCountryIcon--border {
  box-shadow: none;
  border-width: 0px;
  width: 33px;
  height: 22px;
}
