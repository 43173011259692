.Button {
  background-color: #1eb789 !important;
  border-radius: 8px !important;
  border-color: #1eb789 !important;
  width: 100% !important;
  height: 65px !important;
  font-family: "avenir" !important;
  font-family: "avenir" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}
