.cropperStyles {
  background-color: #333;
  position: relative;
  height: 40vh;
  width: "auto";
}
.btn-cancel-modal:hover {
  cursor: pointer;
}
.btn-cancel-modal {
  display: flex;
  justify-content: center; /* horizontal alignment */
  align-items: center; /* vertical alignment */
  width: 150px !important;
  height: 50px !important;
}
.btn-save-modal {
  background: #1eb789 !important;
  border-radius: 15px !important;
  width: 150px !important;
  height: 50px !important;
}
.btn-cancel-modal:hover {
  cursor: pointer;
}
.btn-cancel-modal {
  display: flex;
  justify-content: center; /* horizontal alignment */
  align-items: center; /* vertical alignment */
  width: 150px !important;
  height: 50px !important;
}
.text-demi {
  /* font-family: "Avenir-Demi" !important; */
  font-size: .9rem !important;
}