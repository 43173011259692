.labelStyle {
  font-family: "avenir" !important;
  font-size: 20px !important;
  font-family: "avenir" !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 1) !important;
}
@media screen and (max-width: 600px) {
  .labelStyle {
    font-family: "avenir" !important;
    font-weight: 400 !important;
  }
}
.AppInput {
  border-color: #72a596 !important;
  background-color: #eff8f5 !important;
  font-family: "avenir" !important;
  height: 65px !important;
  border-radius: 9px !important;
}
.passWordStyles {
  padding-top: 3px;
  text-security: disc;
  font-size: 45px !important;
  line-height: 1 !important;
  caret-color: #eff8f5;
  color: #4f4e4e;

  caret-width: 0 !important;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
/* input[type="number"]:focus, */
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: #72a596 !important;
  box-shadow: 0 0px 0px #eff8f5 inset, 0 0 0px #72a596 !important;
  outline: 0 none !important;
}
.InputAddon {
  background: #eff8f5 !important;
  border-color: #72a596 !important;
  z-index: 999 !important;
  margin-right: -10px !important;
  border-right-width: 0px !important;
}
.InputAddonRight {
  background: #eff8f5 !important;
  border-color: #72a596 !important;
  z-index: 999 !important;
  margin-left: -10px !important;
  border-left-width: 0px !important;
}
.pointercursor {
  cursor: pointer;
}
