.banner-right {
  margin-left: 7vw;
}
.AuthRightTopImage {
  width: 390px;
  height: auto;
  margin: 0 auto;
  margin-top: 30px;
}
.minWithStyle {
  min-width: 500px !important;
}

.AuthRightBottomImage {
  width: 350px;
  height: auto;
  margin-top: 60px;
}
@media screen and (max-width: 983px) {
  .minWithStyle {
    display: none !important;
  }
}
.firstText {
  font-family: "avenir" !important;
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-size: 45px;
  font-family: "avenir" !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: center;
  line-height: 50px;
}
.TextContainer {
  margin-top: 40px;
}
.desableScrolling {
  overflow-y: hidden;
}
.scondText {
  opacity: 1;
  color: #00bc83;
  font-size: 45px;
  font-family: "avenir" !important;
  font-weight: 500;
  font-style: Bold;
  letter-spacing: 0px;
  text-align: center;
  line-height: 50px;
  font-family: "avenir" !important;
}

/* @media screen and (max-width: 768px) {
  .firstText {
    font-size: 8vw;
    line-height: 10vw;
  }
  .scondText {
    font-size: 8vw;
    line-height: 10vw;
  }
  .TextContainer {
    margin-top: 9vw;
  }
} */
@media screen and (max-width: 600px) {
  .AuthContainer {
    width: 100%;
    height: 100% !important;
    background-color: red !important;
    padding-left: 1vw;
    /* padding-right: 5vw; */
    padding-top: 5vh;
    position: relative;
  }
}
