.EmptyheaderText1 {
  color: rgba(15, 1, 69, 1);
  font-size: 30px;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  opacity: 1;
  line-height: 25px;
}
.EmptySubHeaderText1 {
  color: rgba(15, 1, 69, 1);
  font-size: 24px;
  font-family: "avenir" !important;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: center;
  line-height: 25px;
}
@media screen and (max-width: 600px) {
  .EmptyheaderText1 {
    color: rgba(15, 1, 69, 1);
    font-size: 16px;
    font-family: "avenir" !important;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: center;
    opacity: 1;
    line-height: 10px;
  }
  .EmptySubHeaderText1 {
    color: rgba(15, 1, 69, 1);
    font-size: 14px;
    font-family: "avenir" !important;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: center;
    line-height: 10px;
  }
}
.EmptyIconContainer {
  max-width: 320px !important;
  margin: 0 auto;
}
.EmptyButtonContainer {
  max-width: 300px !important;
  margin: 0 auto;
  /* height: 50px !important; */
}
.EmptyIcon {
  border: unset !important;
  width: 230px;
}
.btnHight {
  height: 55px !important;
}
