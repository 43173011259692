.mesCodesContainer {
  background-color: #f3f8fa;
  width: 100%;
  height: 100%;
}
.Col-Left {
  background-color: #ffffff;
  border-radius: 8px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.Col-right {
  border-radius: 8px;
}
.statistic-col {
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(200, 217, 224, 1);
}
.statisticScanCountCol {
  border-radius: 8px;
  border-radius: 9px;
  box-shadow: 0px 2px 4px rgba(200, 217, 224, 1);
  opacity: 1;
  background-color: #cfede6;
}
.statisticScanPeoplCountCol {
  border-radius: 8px;
  border-radius: 9px;
  box-shadow: 0px 2px 4px rgba(200, 217, 224, 1);
  opacity: 1;
  background-color: #7d7b99;
}
.statisticIcon {
  width: 41px;
  /* height: 35px; */
}
.minWidthforResponsive {
  min-width: 170px !important;
  height: 100px !important;
}

.searchInputClass2 {
  border: none !important;
}
@media screen and (max-width: 600px) {
  .minWidthforResponsive {
    height: 78px !important;
    min-width: 300px !important;
  }
}
.statisticTextContainer {
  display: flex;
  flex-direction: column; /* Arrange children in a column */
  justify-content: center;
  align-items: flex-start;
  padding-left: 5px;
}
.marginTopstyleforText {
  margin-top: 2px;
}
.statisticsFirstText {
  color: rgba(10, 6, 68, 1);
  font-size: 22px;
  font-family: "avenir" !important;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  /* background-color: red; */
  line-height: 25px;
}
.statisticsSecondText {
  opacity: 1;
  color: rgba(169, 163, 176, 1);
  font-size: 12px;
  font-family: "avenir" !important;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  line-height: 13.5px;
}

.statisticsFirstTextScans {
  opacity: 1;
  color: rgba(0, 186, 134, 1);
  font-size: 27px;
  margin-top: -10px;
  font-family: "avenir" !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  line-height: normal;
}
.statisticsSecondTextScans {
  opacity: 1;
  color: #00bc83;
  font-size: 14px;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  line-height: normal;
  line-height: 13.5px;
}
.statisticsFirstTextPeople {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-size: 27px;
  margin-top: -10px;
  font-family: "avenir" !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  line-height: normal;
}
.statisticsSecondTextScansPeople {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  line-height: normal;
  line-height: 13.5px;
}
.mesCodesText {
  opacity: 1;
  color: rgba(30, 183, 137, 1);
  font-size: 32px;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
}
.searchGreenIcon {
  width: 25px;
  height: 25px;
}
.searchGreenIcon2 {
  width: 16px;
  height: 16px;
}
.cursorEnabled {
  cursor: pointer;
}
.searchInputContainerClass {
  width: 100%;
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
  border-bottom-color: #72a596 !important;
}
.searchInputClass {
  border-color: #fff !important;
  background-color: #fff !important;
  font-family: "avenir" !important;
  border-radius: 9px !important;
  color: rgba(0, 0, 0, 1) !important;
  font-size: 16px !important;
  font-family: "avenir" !important;
  font-weight: 400 !important;
  letter-spacing: 0px !important;
  text-align: left !important;
}
.searchInputClass::placeholder {
  font-family: "avenir" !important;
  color: rgba(0, 0, 0, 1) !important;
  font-size: 16px !important;
  font-family: "avenir" !important;
  font-weight: 400 !important;
  letter-spacing: 0px !important;
  text-align: left !important;
}
textarea:focus,
input[type="text"]:focus,
.uneditable-input:focus {
  border-color: #fff;
  box-shadow: 0 1px 1px #fff inset, 0 0 8px #fff;
  outline: 0 none;
}
.controlContainer textarea:focus,
.controlContainer input[type="text"]:focus,
.controlContainer input:focus {
  border-color: initial !important;
  box-shadow: none !important;
  outline: initial !important;
  /* background-color: red !important; */
  border-width: 0px !important;
}

.AppBg {
  background-color: #f3f8fa;
}
.divider {
  width: 100%;
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
  border-bottom-color: #a5c7bd !important;
}
.overflowClass {
  overflow: scroll;
}
.detailsRow {
  /* width: 100%;
  height: 85%; */
  background-color: #fff;
  border-radius: 8px;
}
.active {
  background-color: #eff8f5;
}
/* .noPadding{
    padding-left: 0px !important;
    padding-right: 0px !important;
  } */
.disableScroll {
  overflow-x: hidden;
}
