.bg-signeIn-color-right{
  background-color: #0F0145;
}
.bg-signeIn-color-left{
  background-color: #FFFFFF;
}
.AuthContainerSign {
  width: 100%;
  height: 100%;
  padding-left: 3vw;
  padding-right: 20vw;
  padding-top: 5vh;
  overflow: auto; 
}
@media screen and (max-width: 600px) {
  .AuthContainerSign {
    padding-left: 0vw;
    padding-right: 0vw;
  }
}
.noScrollx{overflow-x: hidden;} 
/* .overflowScrollY{overflow-y: scroll;} */






