.item {
  background-color: white;
}
.itemIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.itemProIcon {
  width: 29px;
  /* height: 45px; */
}

.itemTextContainer {
  display: flex;
  flex-direction: column; /* Arrange children in a column */
  justify-content: center;
  align-items: flex-start;
  padding-left: 5px;
}
.itemFirstText {
  color: rgba(10, 6, 68, 1);
  font-size: 22px;
  font-family: "avenir" !important;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  line-height: normal;
  margin-bottom: 2px;
}
.itemSecondText {
  opacity: 1;
  color: rgba(169, 163, 176, 1);
  font-size: 14px;
  font-family: "avenir" !important;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  line-height: normal;
}
.pointer {
  cursor: pointer;
}
.hightforItem {
  height: 81px;
}
