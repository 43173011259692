.brandImage {
  width: 290px;
  margin-top: 10px;
  cursor: pointer;
}
.brandImageMobile {
  width: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 5px;
}
.AppNavBarSub {
  height: 100%;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}
.AppMenuCloseContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.toggell,
.toggell:hover,
.toggell:visited,
.toggell:focus {
  margin-top: 10px !important;
  border-width: 0px !important ;
  box-shadow: 0 0 0 0 !important;
}
.brandContainer {
  max-width: 292px;
}
.AppNavBar {
  position: sticky;
  top: 0;
  z-index: 1000;
}
.arrowIconBack {
  width: 18px;
  margin-left: 12px;
  margin-top: 15px;
}

.arrowIcon {
  width: 27px;
  margin-left: 23px;
}
.arrowIcon2 {
  width: 27px;
}
.flagIcon {
  width: 38px;
}
.persIcon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.contactIcon {
  width: 41px;
}
.authContainer {
  display: flex;
  align-items: center;
}
.UserNameContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 120px;
  padding-left: 15px;
  padding-top: 10px;
}
.UserNameContainer2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 120px;
  padding-left: 15px;
}
.UserNameText {
  opacity: 1;
  color: rgba(15, 1, 69, 1);
  font-size: 20px;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  line-height: 20px;
}
.CorpText {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-family: "avenir" !important;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  line-height: 18px;
}
.btnLinkActive {
  min-width: 180px;
  height: 45px;
  background-color: #cfede7;
  border-radius: 8px;
  display: flex;
  /* justify-content: center; */
  padding-left: 17px;
  padding-right: 20px;

  align-items: center;
}
.btnLinkActive2 {
  min-width: 190px;
  height: 45px;
  background-color: #cfede7;
  /* background-color: green; */

  border-radius: 8px;
  display: flex;
  padding-left: 17px;
  padding-right: 20px;

  align-items: center;
}
.btnLink {
  min-width: 170px;
  height: 45px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-right: 20px;
}
.btnIcon {
  width: 20px;
  height: 20px;
}
.btnLinkText {
  opacity: 1;
  color: rgba(30, 183, 137, 1);
  font-size: 20px;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 10px;
  text-decoration: none !important;
}

.dropdown-menu {
  background-color: #fff !important;
  width: 400px !important;
  border-color: #c8d9e0 !important;
}
.DropdownDivider {
  width: 100%;
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
  border-bottom-color: #c8d9e0 !important;
}
.dropdown-item {
  height: 80px !important;
  display: flex !important;
  align-items: center !important;
  padding-left: 40px !important;
}
@media screen and (max-width: 990px) {
  .dropdown-menu {
    width: 250px !important;
  }
}
.dropdownItem {
  height: 57px !important;
}
.dropdownItem:active {
  background-color: rgba(114, 165, 150, 1) !important;
}
.cursorpointer {
  cursor: pointer;
}
.dropdownmenu {
  width: 80px !important;
  min-width: 80px !important;
}
.flagIcon2 {
  width: 30px;
}
.dropdownItem2 {
  height: 35px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.dropdownItem2:active {
  background-color: rgba(114, 165, 150, 1) !important;
}
.classMobileMENU {
  display: none !important;
}
.classmenueLarg {
  display: block !important;
}

@media screen and (max-width: 988px) {
  .classMobileMENU {
    display: block !important;
  }
  .classmenueLarg {
    display: none !important;
  }
}
