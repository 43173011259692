.input-group {
  min-width: 220px !important;
}
.maxHightForContainer {
  /* max-width:  1200px !important; */
}
.maxBtnWith {
  max-width: 400px !important;
}

@media screen and (min-width: 1270px) {
  .maxHightForContainer {
    /* max-width:  1200px !important; */
    padding-right: 200px !important;
  }
}
.creationText {
  opacity: 1;
  color: rgba(30, 183, 137, 1);
  font-size: 32px;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
}
.leftBtnIcon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}
.btnCode {
  /* font-size:0.8vw !important;
    font-family: "avenir" !important; font-weight: 400 !important; */
  /* background-color: rgba(30, 183, 137, 1) !important;
    margin: 200px !important; */
}
.text-piece {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: "avenir" !important;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
}
.responsiveCol {
  min-width: 300px !important;
  /* background-color: pink; */
}
/* @media screen and (max-width: 1480px) {
  .responsiveCol {
    min-width: 230px !important;
  }
} */

.CodeTypeIconUrl {
  width: 32px;
  /* height: 32px; */
}
.CodeTypeIconFacebook {
  width: 18px;
  /* height: 32px; */
}
.CodeTypeIconinstagram {
  width: 25px;
  /* height: 32px; */
}
.CodeTypeIconlikdin {
  width: 28px;
  /* height: 32px; */
}
.CodeTypeIconWhatsapp {
  width: 32px;
  /* height: 32px; */
}
.CodeTypeIconpay {
  width: 32px;
  /* height: 32px; */
}
.CodeTypeIconstores {
  width: 40px;
  /* height: 32px; */
}
.CodeTypeIconyoutube {
  width: 32px;
  /* height: 32px; */
}
.CodeTypeIconImage {
  width: 28px;
  /* height: 32px; */
}
.CodeTypeIconVideo {
  width: 36px;
  /* height: 32px; */
}
.CodeTypeIconAudio {
  width: 22px;
  /* height: 32px; */
}
.CodeTypeIconpdf {
  width: 28px;
  /* height: 32px; */
}
.LeftIcon {
  width: 22px;
  height: 22px;
}
.arrowRight {
  width: 20px;
  height: 12px;
}
#react-select-3-input {
  box-shadow: none !important;
  background-color: transparent !important;
}

.image-selector-container,
.imageContainer,
.react-pdf__Page__canvas,
.react-pdf__Page__textContent {
  width: 200px !important;
  height: 200px !important;
}
/* scroll indecator */

.scroll-down-indicator {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 30px;
  text-align: center;
  animation: bounce 2s infinite;
}
.scroll-down-indicator img {
  width: 100%;
  height: auto;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(10px);
  }
  60% {
    transform: translateY(5px);
  }
}
