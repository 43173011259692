@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600;700;800&display=swap");

html,
body {
  height: 100vh; /* standard definition (as fallback) */
  height: 100dvh; /* dynamic vh will make the trick on iOS */
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "avenir", sans-serif;
  overflow: hidden !important; /* Hide scrollbars */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
}

input:focus-visible {
  outline: none !important;
}

/* Hide increment and decrement buttons on number input */
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* WebKit browsers like Safari and Chrome */
  margin: 0; /* Removes the default margin */
}

::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge, and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
