.textCounter {
  align-self: flex-end;
}

.audio-recorder-container {
  /* min-width: 303px; */
  width: 303px;
  height: 57px;
  background-color: #1eb789;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.audio-recorderContainer {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: row;
}

.audio-recorder-container-play {
  /* min-width: 303px; */
  width: 303px;
  height: 57px;
  background-color: #0F0145;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.playerIcon {
  width: 22px;
  /* height: 34px; */
  cursor: pointer;
  margin-left: -10px;
  margin-right: 10px;
}

.DeleteIcon {
  width: 22px;
  height: 100%;
  cursor: pointer;
  margin-left: 25px;
}

.textCounter-container {
  width: 303px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
