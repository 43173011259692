.btnRmv{
  position: absolute;
  top: -8px;
  right: -8px;
  border: none;
  background-color: transparent !important;
  /* border-radius: 50%; */
  width: 24px;
  height: 24px;
  cursor: pointer;

}
.image-selector-container {
  position: relative;
  display: inline-block;
  width: 150px;
  height: 150px;
}