.EmptyheaderText {
  color: rgba(15, 1, 69, 1);
  font-size: 30px;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
}
.newBtn {
  color: rgba(15, 1, 69, 1) !important;
  font-size: 19px !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  font-family: "avenir" !important;
  text-align: center !important;
  cursor: pointer !important;
  text-decoration: none !important;
}
.EmptySubHeaderText {
  color: rgba(15, 1, 69, 1);
  font-size: 30px;
  font-family: "avenir" !important;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: center;
}
.NoProContainer {
  max-width: 180px !important;
  margin: 0 auto;
}
.NoProButtonContainer {
  max-width: 270px !important;

  margin: 0 auto;
}
.EmptyProIcon {
  border: unset !important;
}.qrCodeLink{
    border: unset !important;
    width: 110px;


}
.textActiverlemodepro {
  opacity: 1;
  color: #0f0145;
  font-size: 25px;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  line-height: 10px;
}
.textActiverlemodepro2 {
  opacity: 1;
  color: #000;
  font-size: 24px;
  font-family: "avenir" !important;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  /* line-height: 10px; */
}
.btnHightA {
  height: 55px !important;
}
