.CopyrightContainer {
  width: 100%;
  text-align: center;
  opacity: 1;
  color: rgba(94, 94, 94, 1);
  font-size: 18px;
  font-family: "avenir" !important;
  font-weight: 400;
  letter-spacing: 0px;
  margin-top: auto;
  height: 90px;
  position: absolute;
  bottom: 0px;
  text-align: center;
  margin-left: -3vw;
}
