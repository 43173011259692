.css-1jmjxfr-control:focus {
  background-color: black !important;
}
.custom {
  background-color: red !important;
}
.react-select-3-input {
  background-color: black !important;
}
.css-hz3ksy-Input input {
  background-color: transparent !important;
  box-sizing: unset !important;
  box-shadow: none !important;
  box-shadow-color: rgba(0, 0, 0, 0) !important;
  box-shadow-offset-x: 0 !important;
  box-shadow-offset-y: 0 !important;
  box-shadow-blur: 0 !important;
  box-shadow-spread: 0 !important;
  box-shadow-inset: false !important;
}


