.modal-title {
  color: #fff;
  font-family: "avenir" !important;
  font-weight: bold;
  font-size: 1.5em !important;
  text-align: right;
}

.modal {
  top: 50px !important;
  border-radius: 7px 7px 0 0 !important;
}
.modal-header {
  background: #1eb789;
  box-shadow: 0 4px 4px 0 rgba(213, 213, 213, 0.5);
  padding: 10px !important;
  height: 70px !important;
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
}
.modal-content .modal-header button {
  top: 22px !important;
}

.modal-content .modal-footer {
  display: flex;
  justify-content: center !important;
}
.modal-content .modal-footer2 {
  display: flex;
  justify-content: end !important;
}
.modal-close-btn {
  cursor: pointer;
  top: 22px !important;
}
.discription {
  font-size: large;
  font-family: "avenir" !important;
  font-weight: 400;
  line-height: 25px;
  padding-bottom: 20px;
}
.ModalInsideItem {
  /* justify-content: space-between; */
  display: flex;
  align-content: center;
}
.countingCercle {
  width: 25px;
  height: 25px;
  border-radius: 20px;
  background-color: #0f0145;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px !important;
}
.modal-content .modal-footer2 {
  display: flex;
  justify-content: end !important;
}
.btn-Global-fermer {
  background: #fff !important;
  /* border: 1px solid #979797 !important; */
  border-radius: 35px !important;
  font-size: 16px !important;
  color: #373737 !important;
  letter-spacing: 0;
  /* z-index: 100 !important; */
  min-width: 150px;
}

.btn-Global {
  background: #1eb789 !important;
  /* border: 1px solid #979797 !important; */
  border-radius: 35px !important;
  font-size: 16px !important;
  color: #ffffff !important;
  letter-spacing: 0;
  /* z-index: 100 !important; */
  min-width: 150px;
}
.BtncloseMofdal {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.btn-Modal-Save {
  background: #1eb789 !important;
  border-radius: 35px !important;
  font-size: 16px !important;
  color: #fff !important;
  letter-spacing: 0;
  min-width: 150px;
}
