.CheckBox input{
    width: 25px !important;
    height:  25px !important;
    border-radius: 8px !important;
    border-color: 373737 !important;
        margin-right: 5px;
          border-color: #1EB789 !important;



}
.CheckBox input :checked{
    background-color: 72a596 !important;
    /* width: 25px !important;
    height:  25px !important;
    border-radius: 8px !important;
    border-color: 373737 !important; */

}

input[type="checkbox"]:focus,
.uneditable-input:focus {   
  border-color: #1EB789;
  box-shadow: 0 0px 1px #eff8f5 inset, 0 0 8px #1EB789;
  outline: 0 none;
}
.form-check-input:checked{
    background-color: #1EB789 !important;
    /* width: 25px !important; */
    height:  25px !important;
    border-radius: 8px !important;
    border-color: 373737 !important;
}

 