.CodeBtnContainer {
  width: 75px !important;
  margin-right: 20px;
}
.CodeBtn {
  border-width: 2px !important;
  border-radius: 8px !important;
  border-color: rgba(114, 165, 150, 1) !important;
  width: 75px !important;
  height: 75px !important;
  min-width: 75px !important;
  min-height: 75px !important;
  max-width: 75px !important;
  max-height: 75px !important;
  font-family: "avenir" !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  color: rgba(114, 165, 150, 1) !important;
  font-size: 16px !important;
}

.CodeBtn:hover {
  border-width: 2px !important;

  background-color: #ffffff !important;
  border-radius: 8px !important;
  border-color: rgba(114, 165, 150, 1) !important;
  width: 100% !important;
  height: 65px !important;
  font-family: "avenir" !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: rgba(114, 165, 150, 1) !important;
}
.CodeBtnActive {
  border-width: 2px !important;

  border-radius: 8px !important;
  border-color: rgba(30, 183, 137, 1) !important;
  width: 75px !important;
  height: 75px !important;
  min-width: 75px !important;
  min-height: 75px !important;
  max-width: 75px !important;
  max-height: 75px !important;
  font-family: "avenir" !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  color: rgba(114, 165, 150, 1) !important;
  font-size: 16px !important;
  background-color: rgba(30, 183, 137, 1) !important;
}
.CodeBtnActive:hover {
  border-width: 2px !important;

  border-radius: 8px !important;
  border-color: #fff !important;
  width: 100% !important;
  height: 65px !important;
  font-family: "avenir" !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: rgba(114, 165, 150, 1) !important;
  background-color: rgba(30, 183, 137, 1) !important;
}
.CodelabelStyle {
  color: rgba(114, 165, 150, 1) !important;
  font-family: "avenir" !important;
  font-size: 14px !important;
  font-family: "avenir" !important;
  font-weight: 600;
  letter-spacing: 0px !important;
  text-align: center !important;
  line-height: 20px !important;
  margin-bottom: 0px !important;
}
.CodeInfoStyle {
  color: black !important;
  font-family: "avenir" !important;
  font-size: 14px !important;
  font-family: "avenir" !important;
  font-weight: 400;
  letter-spacing: 0px !important;
  text-align: center !important;
  line-height: 20px !important;
  cursor: pointer;
}
