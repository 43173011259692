.MCodeBtnContainer {
  width: 50px !important;
  margin-right: 10px;
}
@media screen and (max-width: 600px) {
  .MCodeBtnContainer {
    width: 50px !important;
    margin-right: 3px;
  }

}
.MCodeBtn {
  border-radius: 4px !important;
  border-color: #000 !important;
  width: 50px !important;
  height: 50px !important;
  min-width: 50px !important;
  min-height: 50px !important;
  max-width: 50px !important;
  max-height: 50px !important;
  font-family: "avenir" !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  color: #000 !important;
  font-size: 16px !important;
}

.MCodeBtn:hover {
  background-color: #ffffff !important;
  border-radius: 4px !important;
  border-color: #000 !important;
  font-family: "avenir" !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #000 !important;
}
.MCodeBtnActive {
  border-radius: 4px !important;
  border-color: #1eb789 !important;
  width: 50px !important;
  height: 50px !important;
  min-width: 50px !important;
  min-height: 50px !important;
  max-width: 50px !important;
  max-height: 50px !important;
  font-family: "avenir" !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  color: #000 !important;
  font-size: 16px !important;
  background-color: #1eb789 !important;
}
.MCodeBtnActive:hover {
  background-color: #1eb789 !important;
  border-radius: 4px !important;
  border-color: #000 !important;
  /* width: 100% !important; */
  /* height: 65px !important; */
  font-family: "avenir" !important;
  font-family: "avenir" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #1eb789 !important;
}
.MCodelabelStyle {
  color: #000 !important;
  font-family: "avenir" !important;
  font-size: 14px !important;
  font-family: "avenir" !important;
  font-weight: 400;
  letter-spacing: 0px !important;
  text-align: center !important;
  line-height: 20px !important;
}
